import { Component, OnInit } from '@angular/core';
import { ContactService } from '../service/contact.service';
import { Router } from '@angular/router';

declare var $: any;
const SESSION_KEY = 'Email';
@Component({
  selector: 'app-admin-contact-us',
  templateUrl: './admin-contact-us.component.html',
  styleUrls: ['./admin-contact-us.component.css']
})
export class AdminContactUsComponent implements OnInit {

  public static isValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isUpdateMode: boolean = false;
  ContactDetailArray: any;
  contactArray: any = {};
  sessionToken = "";
  constructor(private contactService: ContactService, private router: Router) { }

  ngOnInit() {
    this.sessionToken = localStorage.getItem(SESSION_KEY);
    if (this.sessionToken != null) {
      this.contactService.getContactDetail().then(res => {
        this.ContactDetailArray = res;
      });
    }
    else {
      this.router.navigate(['admin/login']);
    }
  }
  OpenInsertContactModal() {
    this.isUpdateMode = false;
    this.contactArray = {};
    $("#contactModal").modal('show');
  }

  OpenUpdateContactModal(editData: any) {
    this.isUpdateMode = true;
    this.contactArray = editData;
    $("#contactModal").modal('show');
  }

  IsDelete(Id) {
    if (confirm("Are you sure want to delete?")) {
      this.contactService.DeleteContact(Id).then(() => {
        alert("deleted...");
        location.reload();
      })

    }
  }
  insertHotelDetail() {
    let isValid = AdminContactUsComponent.isValid(this.contactArray.Email);
    if (this.contactArray.Name && this.contactArray.Email && isValid) {
      this.contactService.insertContactDetailData(this.contactArray).then(res => {
        alert("Contact Detail Added Successfully");
        location.reload();
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields or valid field value.");
    }
  }

  updateHotelDetail() {
    let isValid = AdminContactUsComponent.isValid(this.contactArray.Email);
    if (this.contactArray.Name && this.contactArray.Email && isValid) {
      this.contactService.updateContactDetailData(this.contactArray).then(res => {
        alert("Contact Detail Updated Successfully");
        location.reload();
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields or valid field value.");
    }
  }

}
