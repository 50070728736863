import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';

import { UserService } from './service/user.service';
import { ToursService } from './service/tours.service';
import { HotelsService } from './service/hotels.service';
import { FileUploadService } from './service/file-upload.service';
import { ContactService } from './service/contact.service';
import { PagerService } from './service/pager.service';

import { AppComponent } from './app.component';
import { ToursComponent } from './tours/tours.component';
import { HotelsComponent } from './hotels/hotels.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { SliderComponent } from './slider/slider.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { ImageWithContentComponent } from './image-with-content/image-with-content.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminToursComponent } from './admin-tours/admin-tours.component';
import { AdminHotelsComponent } from './admin-hotels/admin-hotels.component';
import { AdminContactUsComponent } from './admin-contact-us/admin-contact-us.component';
import { AdminAboutUsComponent } from './admin-about-us/admin-about-us.component';
import { AdminDestinationsComponent } from './admin-destinations/admin-destinations.component';
import { DestinationService } from './service/destination.service';
import { HomeComponent } from './home/home.component';
import { ClientServiceComponent } from './client-service/client-service.component';
import { ServiceContentComponent } from './service-content/service-content.component';

@NgModule({
  declarations: [
    AppComponent,
    ToursComponent,
    HotelsComponent,
    AboutusComponent,
    ContactComponent,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    ImageWithContentComponent,
    AdminLoginComponent,
    AdminUsersComponent,
    AdminToursComponent,
    AdminHotelsComponent,
    AdminContactUsComponent,
    AdminAboutUsComponent,
    AdminDestinationsComponent,
    HomeComponent,
    ClientServiceComponent,
    ServiceContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
    BlockUIModule.forRoot()
  ],
  providers: [ContactService,DestinationService,FileUploadService,HotelsService,ToursService,UserService,PagerService],
  bootstrap: [AppComponent],    
})
export class AppModule { }
