import { Component, OnInit } from '@angular/core';
import { ToursService } from '../service/tours.service';
import { FileUploadService } from '../service/file-upload.service';
import { Router } from '@angular/router';

declare var $: any;
const SESSION_KEY = 'Email';
@Component({
  selector: 'app-admin-tours',
  templateUrl: './admin-tours.component.html',
  styleUrls: ['./admin-tours.component.css']
})

export class AdminToursComponent implements OnInit {
  isUpdateMode: boolean = false;
  tourArray: any = {};
  tourDetailArray: any;
  DestinationsArray: any;
  sessionToken = "";
  constructor(private toursService: ToursService, private fileUploadService: FileUploadService, private router: Router) { }

  ngOnInit() {
    this.sessionToken = localStorage.getItem(SESSION_KEY);
    if (this.sessionToken != null) {
      this.toursService.getTourDetail().then(res => {
        this.tourDetailArray = res;
      });
      this.toursService.getDestinationDetail().then(res => {
        this.DestinationsArray = res;
      })
    }
    else {
      this.router.navigate(['admin/login']);
    }
  }
  OpenInsertTourModal() {
    this.isUpdateMode = false;
    this.tourArray = {};
    $("#myModal").modal('show');
  }

  OpenUpdateTourModal(editData: any) {
    this.isUpdateMode = true;
    //editData.DepartureDate = new Date(editData.DepartureDate);
    editData.DepartureDate = editData.DepartureDate.split("T")[0];
    this.tourArray = editData;
    $("#myModal").modal('show');
  }

  IsDelete(Id) {
    if (confirm("Are you sure want to delete?")) {
      this.toursService.DeleteTour(Id).then(() => {
        alert("Selected Tour Deleted Successfully.");
        location.reload();
      })

    }
  }

  insertTourDetail() {
    if (this.tourArray.TourName && this.tourArray.Destinations) {
      this.toursService.insertTourDetailData(this.tourArray).then(res => {

        if (this.tourArray.imageUpload) {
          this.fileUploadService.ImageUpload(this.tourArray.imageUpload).then(res => {
            alert("Tour Detail Added Successfully.");
            location.reload();
          }).catch(e => {
            console.log(e);
          });

        }
        else {
          alert("Tour Detail Added Successfully.");
          location.reload();
        }

      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields.");
    }
  }

  updateTourDetail() {
    if (this.tourArray.TourName && this.tourArray.Destinations) {
      this.toursService.updateTourDetailData(this.tourArray).then(res => {
        if (this.tourArray.imageUpload) {
          this.fileUploadService.ImageUpload(this.tourArray.imageUpload).then(res => {
            alert("Tour Detail Updated Successfully.");
            location.reload();
          }).catch(e => {
            console.log(e);
          });

        }
        else {
          alert("Tour Detail Updated Successfully.");
          location.reload();
        }
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields.");
    }
  }

  fileChange(event) {
    this.docChoose(event);
    let fileList: FileList = event.target.files;
    this.tourArray.imageUpload = fileList;
  }

  public docChoose(fileInput: any) {

    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(fileInput.target.files[0]);

      this.tourArray.TourImages = fileInput.target.files[0].name;


    }
  }
}
