import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  tour: any = {};
  hotel: any = {};
  months: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  constructor(private router: Router) { }

  ngOnInit() {
    this.tour.month = "";
  }

  goToProductDetails(id) {
    this.router.navigate(['/product-details', id]);
  }

  searchTour() {
    // this.tour = {};
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "name": this.tour.name,
        "destination": this.tour.destination,
        "month": this.tour.month
      }
    };
    this.router.navigate(["tours"], navigationExtras);
  }

  searchHotel() {
    console.log(this.hotel);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "name": this.hotel.name,
        "location": this.hotel.location
      }
    }
    this.router.navigate(["hotels"], navigationExtras);
  }

}
