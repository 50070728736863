import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  imgBaseUrl: string = "";

  constructor() {
    this.imgBaseUrl = environment.imgBaseUrl;
   }

  ngOnInit() {
  }

}
