import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
declare function loadMain(): any;
@Component({
  selector: 'app-image-with-content',
  templateUrl: './image-with-content.component.html',
  styleUrls: ['./image-with-content.component.css']
})
export class ImageWithContentComponent implements OnInit {
  imgBaseUrl: string = "";
  constructor() { 
    this.imgBaseUrl = environment.imgBaseUrl;
  }

  ngOnInit() {
    loadMain();
  }

}
