import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
declare var $: any;
declare function loadMain(): any;
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  imgBaseUrl: string = "";
  constructor() {
    this.imgBaseUrl = environment.imgBaseUrl;
  }

  ngOnInit() {
    loadMain();
  }

}
