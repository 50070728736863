import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelsService {
  
   // serviceBaseUrl: string = "http://localhost:61000/Jiya/";
   serviceBaseUrl: string = "";

   constructor(private httpClient: HttpClient) {
     this.serviceBaseUrl = environment.serviceBaseUrl;
   }

  getHotelDetail() {
    return this.httpClient.get(this.serviceBaseUrl + "GetHotels").toPromise();
  }
  insertHotelDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "InsertHotels", obj).toPromise();
  }
  updateHotelDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "UpdateHotels", obj).toPromise();
  }
  DeleteHotel(Id: string) {
    return this.httpClient.get(this.serviceBaseUrl + "DeleteHotels?Id=" + Id).toPromise();
  }
}
