import { Component, OnInit } from '@angular/core';
import { DestinationService } from '../service/destination.service';
import { Router } from '@angular/router';

declare var $: any;
const SESSION_KEY = 'Email';
@Component({
  selector: 'app-admin-destinations',
  templateUrl: './admin-destinations.component.html',
  styleUrls: ['./admin-destinations.component.css']
})
export class AdminDestinationsComponent implements OnInit {
  isUpdateMode: boolean = false;
  destinationArray: any = {};
  destinationDetailArray: any;
  sessionToken = "";
  constructor(private destinationService: DestinationService, private router: Router) { }

  ngOnInit() {
    this.sessionToken = localStorage.getItem(SESSION_KEY);
    if (this.sessionToken != null) {
      this.destinationService.getDestinationDetail().then(res => {
        this.destinationDetailArray = res;
      });
    }
    else {
      this.router.navigate(['admin/login']);
    }
  }
  OpenInsertDestinationModal() {
    this.isUpdateMode = false;
    this.destinationArray = {};
    $("#destinationModal").modal('show');
  }

  OpenUpdateDestinationModal(editData: any) {
    this.isUpdateMode = true;
    this.destinationArray = editData;
    $("#destinationModal").modal('show');
  }

  IsDelete(Id) {
    if (confirm("Are you sure want to delete?")) {
      this.destinationService.DeleteDestination(Id).then(() => {
        alert("Selected Destination Deleted Successfully.");
        location.reload();
      })

    }
  }
  insertDestinationDetail() {
    if (this.destinationArray.DestinationName) {
      this.destinationService.insertDestinationDetailData(this.destinationArray).then(res => {
        alert("Destination Detail Added Successfully.");
        location.reload();
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields.");
    }
  }

  updateDestinationDetail() {
    if (this.destinationArray.DestinationName) {
      this.destinationService.updateDestinationDetailData(this.destinationArray).then(res => {
        alert("Destination Detail Updated Successfully.");
        location.reload();
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields.");
    }
  }
}
