import { Component, OnInit } from '@angular/core';
import { HotelsService } from './../service/hotels.service';
import { PagerService } from './../service/pager.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})
export class HotelsComponent implements OnInit {
  imgBaseUrl: string = "";
  
  // public hotel: any[];
  hotel = [];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

  hotelpar: any = {};

  paramHotels: any = {};

  NodataFound = false;

  constructor(public hotelsService: HotelsService, private pagerService: PagerService, private route: ActivatedRoute, private router: Router) {
    this.imgBaseUrl = environment.imgBaseUrl;
   }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params["name"])
        this.paramHotels.name = params["name"]
      if (params["location"])
        this.paramHotels.location = params["location"]
      this.getHotels();
    })
  }

  getHotels() {
    this.hotelsService.getHotelDetail().then(res => {
      let result = res as any[];

      let filteredResult = [];
      //this.hotel = res as any[];
      if (this.paramHotels.name == null && this.paramHotels.location == null) {
        for (let i = 0; i < result.length; i++) {
          if (result[i].HotelImage != null && result[i].HotelImage != "") {
            this.hotel.push({ "Id": result[i].Id, "HotelName": result[i].HotelName, "HotelDescription": result[i].HotelDescription, "HotelImage": result[i].HotelImage, "Location": result[i].Location, "MobileNo": result[i].MobileNo, "isDefaultImg": false });
          }
          else {
            this.hotel.push({ "Id": result[i].Id, "HotelName": result[i].HotelName, "HotelDescription": result[i].HotelDescription, "HotelImage": result[i].HotelImage, "Location": result[i].Location, "MobileNo": result[i].MobileNo, "isDefaultImg": true });
          }
        }
      }
      else {
        for (var i = 0; i < result.length; i++) {
          if (this.paramHotels.name != null && result[i].HotelName.indexOf(this.paramHotels.name) != -1) {
            // filteredResult.push(result[i]);
            if (result[i].HotelImage != null && result[i].HotelImage != "") {
              filteredResult.push({ "Id": result[i].Id, "HotelName": result[i].HotelName, "HotelDescription": result[i].HotelDescription, "HotelImage": result[i].HotelImage, "Location": result[i].Location, "MobileNo": result[i].MobileNo, "isDefaultImg": false });
            }
            else {
              filteredResult.push({ "Id": result[i].Id, "HotelName": result[i].HotelName, "HotelDescription": result[i].HotelDescription, "HotelImage": result[i].HotelImage, "Location": result[i].Location, "MobileNo": result[i].MobileNo, "isDefaultImg": true });
            }
          }
          else if (this.paramHotels.location != null && result[i].Location.indexOf(this.paramHotels.location) != -1) {
            // filteredResult.push(result[i]);
            if (result[i].HotelImage != null && result[i].HotelImage != "") {
              filteredResult.push({ "Id": result[i].Id, "HotelName": result[i].HotelName, "HotelDescription": result[i].HotelDescription, "HotelImage": result[i].HotelImage, "Location": result[i].Location, "MobileNo": result[i].MobileNo, "isDefaultImg": false });
            }
            else {
              filteredResult.push({ "Id": result[i].Id, "HotelName": result[i].HotelName, "HotelDescription": result[i].HotelDescription, "HotelImage": result[i].HotelImage, "Location": result[i].Location, "MobileNo": result[i].MobileNo, "isDefaultImg": true });
            }
          }
          if (filteredResult.length > 0) {
            this.NodataFound = false;
            this.hotel = filteredResult;
          }
          else {
            this.NodataFound = true;
          }
        }
      }
      this.setPage(1);
    }).catch(err => {
      console.log(err);
    });
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.hotel.length, page);

    // get current page of items
    this.pagedItems = this.hotel.slice(this.pager.startIndex, this.pager.endIndex + 1);

  }

  searchHotel() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "name": this.hotelpar.name,
        "location": this.hotelpar.location
      }
    }
    this.router.navigate(["hotels"], navigationExtras);
  }

}
