import { Component, OnInit } from '@angular/core';
declare var $: any;
import { HotelsService } from '../service/hotels.service';
import { FileUploadService } from '../service/file-upload.service';
import { Router } from '@angular/router';

declare var $: any;
const SESSION_KEY = 'Email';
@Component({
  selector: 'app-admin-hotels',
  templateUrl: './admin-hotels.component.html',
  styleUrls: ['./admin-hotels.component.css']
})
export class AdminHotelsComponent implements OnInit {
  HotelsArray: any;
  isUpdateMode: boolean = false;
  hotelArray: any = {};
  sessionToken = "";
  constructor(private hotelService: HotelsService, private fileUploadService: FileUploadService, private router: Router) { }

  ngOnInit() {
    this.sessionToken = localStorage.getItem(SESSION_KEY);
    if (this.sessionToken != null) {
      this.hotelService.getHotelDetail().then(res => {
        this.HotelsArray = res;
      })
    }
    else {
      this.router.navigate(['admin/login']);
    }
  }
  OpenInsertHotelModal() {
    this.isUpdateMode = false;
    this.hotelArray = {};
    $("#hotelModal").modal('show');
  }

  OpenUpdateHotelModal(editData: any) {
    this.isUpdateMode = true;
    this.hotelArray = editData;
    $("#hotelModal").modal('show');
  }

  IsDelete(Id) {
    if (confirm("Are you sure want to delete?")) {
      this.hotelService.DeleteHotel(Id).then(() => {
        alert("deleted...");
        location.reload();
      })

    }
  }
  insertHotelDetail() {
    if (this.hotelArray.HotelName) {
      this.hotelService.insertHotelDetailData(this.hotelArray).then(res => {
        if (this.hotelArray.imageUpload) {
          this.fileUploadService.ImageUpload(this.hotelArray.imageUpload).then(res => {
            alert("Hotel Detail Added Successfully.");
            location.reload();
          }).catch(e => {
            console.log(e);
          });

        }
        else {
          alert("Hotel Detail Added Successfully.");
          location.reload();
        }

      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields.");
    }
  }

  updateHotelDetail() {
    if (this.hotelArray.HotelName) {
      this.hotelService.updateHotelDetailData(this.hotelArray).then(res => {
        if (this.hotelArray.imageUpload) {
          this.fileUploadService.ImageUpload(this.hotelArray.imageUpload).then(res => {
            alert("Hotel Detail Updated Successfully.");
            location.reload();
          }).catch(e => {
            console.log(e);
          });

        }
        else {
          alert("Hotel Detail Updated Successfully.");
          location.reload();
        }
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please fill required(*) fields.");
    }
  }

  fileChange(event) {
    this.docChoose(event);
    let fileList: FileList = event.target.files;
    this.hotelArray.imageUpload = fileList;
  }

  public docChoose(fileInput: any) {

    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(fileInput.target.files[0]);

      this.hotelArray.HotelImage = fileInput.target.files[0].name;


    }
  }

}
