import { Component, OnInit } from '@angular/core';
import { ContactService } from '../service/contact.service';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  imgBaseUrl: string = "";

  @BlockUI() blockUI: NgBlockUI;
  contact: any = {
    'Name': '',
    'Email': '',
    'Phone': '',
    'Message': ''
  }
  constructor(private contactService: ContactService, private router: Router) {
    this.imgBaseUrl = environment.imgBaseUrl;
  }

  ngOnInit() {
  }

  Submit(contact) {
    this.blockUI.start('Please Wait...');
    // this.contactService.clientinsertContact(contact)
    //   .then(res => {
    //     this.blockUI.stop();
    //     console.log(res);
    //     alert("Successfully inserted your message");
    //     location.reload();
    //     this.contact = { 'Name': '', 'Email': '', 'Message': '' };
    //   })
    //   .catch(err => { console.log(err); this.blockUI.stop(); });
    console.log(contact);
  }
}
