import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './../home/home.component';
import { ToursComponent } from './../tours/tours.component';
import { HotelsComponent } from './../hotels/hotels.component';
import { AboutusComponent } from './../aboutus/aboutus.component';
import { ContactComponent } from './../contact/contact.component';
import { AdminLoginComponent } from './../admin-login/admin-login.component';
import { AdminToursComponent } from './../admin-tours/admin-tours.component';
import { AdminHotelsComponent } from './../admin-hotels/admin-hotels.component';
import { AdminContactUsComponent } from './../admin-contact-us/admin-contact-us.component';
import { AdminDestinationsComponent } from './../admin-destinations/admin-destinations.component';
import { AdminUsersComponent } from './../admin-users/admin-users.component';
import { ClientServiceComponent } from '../client-service/client-service.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  // { path: 'tours', component: ToursComponent },
  // { path: 'tours/:query', component: ToursComponent },
  // { path: 'hotels', component: HotelsComponent },
  // { path: 'hotels/:query', component: HotelsComponent },
  { path: 'about', component: AboutusComponent },
  { path: 'service', component: ClientServiceComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'admin',
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: AdminLoginComponent },
      { path: 'adminTours', component: AdminToursComponent },
      // { path: 'adminHotels', component: AdminHotelsComponent },
      { path: 'adminContacts', component: AdminContactUsComponent },
      { path: 'adminDestinations', component: AdminDestinationsComponent },
      { path: 'adminUsers', component: AdminUsersComponent }
    ]
  },
  { path: '**', component: HomeComponent }
  //{ path: 'detail/:id', component: HeroDetailComponent },
  //{ path: 'heroes', component: HeroesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }