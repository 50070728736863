import { Component, OnInit } from '@angular/core';
import { UserService } from './../service/user.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

const SESSION_KEY = 'Email';
const STORELAST_KEY = 'lastAction';
const MINUTES_UNITL_AUTO_LOGOUT = 60; // in mins
const CHECK_INTERVAL = 15000; // in ms

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  imgBaseUrl: string = "";

  // user: any = {};
  user = {
    Email: '',
    Password: ''
  }

  IsError = false;
  sessionToken = "";

  constructor(public userService: UserService, private router: Router) {
    this.imgBaseUrl = environment.imgBaseUrl;
    localStorage.setItem(STORELAST_KEY, Date.now().toString());
    this.checkSession();
    this.initInterval();
  }

  ngOnInit() {

    this.sessionToken = localStorage.getItem(SESSION_KEY);
    if (this.sessionToken != null) {
      this.router.navigate(['admin/adminTours']);
    }

  }

  // Admin Login
  login() {
    this.userService
      .GetUserLogin(this.user.Email, this.user.Password)
      .then(res => {

        localStorage.setItem(SESSION_KEY, this.user.Email);
        this.setLastAction(Date.now());
        this.router.navigate(['admin/adminTours']);
        this.clearAdminLoginControl();

      }).catch(err => {
        this.IsError = true;
        this.clearAdminLoginControl();
        console.log(err);
      });
  }

  // Clear Admin Login Control
  clearAdminLoginControl() {
    this.user = {
      Email: '',
      Password: ''
    }
  }

  // Get Last Action Time
  getLasAction() {
    return parseInt(localStorage.getItem(STORELAST_KEY));
  }

  // Set Current Time Last Action
  setLastAction(lastAction: number) {
    localStorage.setItem(STORELAST_KEY, lastAction.toString());
  }

  // Auto Call 
  initInterval() {
    setInterval(() => {
      this.checkSession();
    }, CHECK_INTERVAL);
  }

  // Check Session
  checkSession() {
    const now = Date.now();
    const timeleft = this.getLasAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
      localStorage.clear();
      this.router.navigate(['admin/login']);
    }
  }


}
