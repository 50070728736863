import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUrl: string = "";
  @Input() IsAdminView: boolean = false;
  constructor(private router: Router) { }

  ngOnInit() {
     this.currentUrl = this.router.url;
    // console.log("currentUrl", this.currentUrl);
  }

  logOut(){
    localStorage.clear();
    this.router.navigate(['/admin']);
  }

}
