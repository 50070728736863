import { Component, OnInit } from '@angular/core';
import { ToursService } from './../service/tours.service';
import { PagerService } from './../service/pager.service';
import { ActivatedRoute } from '@angular/router';
import { Condition } from 'selenium-webdriver';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css']
})

export class ToursComponent implements OnInit {
  imgBaseUrl: string = "";
  // array of all items to be paged
  // public tours: any[];
  tours = [];
  paramtours: any = {};
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

  tourPar: any = {};

  months: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  NodataFound = false;

  constructor(public toursService: ToursService, private pagerService: PagerService, private route: ActivatedRoute, private router: Router) { 
    this.imgBaseUrl = environment.imgBaseUrl;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params["name"]) {
        this.paramtours.name = params["name"];
      }
      else {
        this.paramtours.name = null;
      }
      if (params["destination"]) {
        this.paramtours.destination = params["destination"];
      } else {
        this.paramtours.destination = null;
      }
      if (params["month"]) {
        this.paramtours.month = params["month"];
      } else {
        this.paramtours.month = null;
      }
      this.getTourData();
    });
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.tours.length, page);

    // get current page of items
    this.pagedItems = this.tours.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getTourData() {
    this.toursService.getTourDetail().then(res => {
      let result = res as any[];
      let filteredResult = [];
      if (this.paramtours.name == null && this.paramtours.destination == null && this.paramtours.month == null) {
        // this.tours = result;

        for (let i = 0; i < result.length; i++) {
          if (result[i].TourImages != null && result[i].TourImages != "") {
            this.tours.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": false });
          }
          else {
            this.tours.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": true });
          }
        }
      }
      else {
        for (var i = 0; i < result.length; i++) {
          if (this.paramtours.name != null && result[i].TourName.indexOf(this.paramtours.name) != -1) {
            // filteredResult.push(result[i]);
            if (result[i].TourImages != null && result[i].TourImages != "") {
              filteredResult.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": false });
            }
            else {
              filteredResult.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": true });
            }
          }
          else if (this.paramtours.destination != null && result[i].Destinations.indexOf(this.paramtours.destination) != -1) {
            // filteredResult.push(result[i]);
            if (result[i].TourImages != null && result[i].TourImages != "") {
              filteredResult.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": false });
            }
            else {
              filteredResult.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": true });
            }
          }
          else if (this.paramtours.month != null && (new Date(result[i].DepartureDate)).getMonth() == (this.paramtours.month - 1)) {
            // filteredResult.push(result[i]);
            if (result[i].TourImages != null && result[i].TourImages != "") {
              filteredResult.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": false });
            }
            else {
              filteredResult.push({ "Id": result[i].Id, "TourName": result[i].TourName, "TourImages": result[i].TourImages, "TourDays": result[i].TourDays, "Destinations": result[i].Destinations, "DepartureTime": result[i].DepartureTime, "DepartureDate": result[i].DepartureDate, "BusType": result[i].BusType, "BusACType": result[i].BusACType, "isDefaultImg": true });
            }
          }
          if (filteredResult.length > 0) {
            this.NodataFound = false;
            this.tours = filteredResult;
          }
          else {
            this.NodataFound = true;
          }
        }
      }
      // initialize to page 1
      this.setPage(1);
    }).catch(err => {
      console.log(err);
    });
  }

  searchTour() {
    // this.tour = {};
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "name": this.tourPar.name,
        "destination": this.tourPar.destination,
        "month": this.tourPar.month
      }
    };
    this.router.navigate(["tours"], navigationExtras);
  }

}
