import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  // serviceBaseUrl: string = "http://localhost:61000/Jiya/";
  serviceBaseUrl: string = "";

  constructor(private httpClient: HttpClient) {
    this.serviceBaseUrl = environment.serviceBaseUrl;
  }

  getUserDetail() {
    return this.httpClient.get(this.serviceBaseUrl + "GetUsers").toPromise();
  }
  insertUserDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "InsertUsers", obj).toPromise();
  }
  updateUserDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "UpdateUsers", obj).toPromise();
  }
  DeleteUser(Id: string) {
    return this.httpClient.get(this.serviceBaseUrl + "DeleteUsers?Id=" + Id).toPromise();
  }
  GetUserLogin(userName: string, password: string) {
    return this.httpClient.get(this.serviceBaseUrl + "GetUserLogin?Username=" + userName + "&Password=" + password).toPromise();
  }
}
