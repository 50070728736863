import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DestinationService {
  
  // serviceBaseUrl: string = "http://localhost:61000/Jiya/";
  serviceBaseUrl: string = "";

  constructor(private httpClient: HttpClient) {
    this.serviceBaseUrl = environment.serviceBaseUrl;
  }
  
  getDestinationDetail() {
    return this.httpClient.get(this.serviceBaseUrl + "GetDestinations").toPromise();
  }
  insertDestinationDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "InsertDestinations", obj).toPromise();
  }
  updateDestinationDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "UpdateDestinations", obj).toPromise();
  }
  DeleteDestination(Id: string) {
    return this.httpClient.get(this.serviceBaseUrl + "DeleteDestinations?Id=" + Id).toPromise();
  }
}
