import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  // serviceBaseUrl: string = "http://localhost:61000/Jiya/";
  serviceBaseUrl: string = "";

  constructor(private httpClient: HttpClient) {
    this.serviceBaseUrl = environment.serviceBaseUrl;
  }

  getContactDetail() {
    return this.httpClient.get(this.serviceBaseUrl + "GetContact").toPromise();
  }
  insertContactDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "InsertContact", obj).toPromise();
  }
  updateContactDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "UpdateContact", obj).toPromise();
  }
  DeleteContact(Id: string) {
    return this.httpClient.get(this.serviceBaseUrl + "DeleteContact?Id=" + Id).toPromise();
  }
  clientinsertContact(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "InsertContact", obj).toPromise();
  }
}
