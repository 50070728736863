import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { environment } from '../../environments/environment';
//import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  // serviceBaseUrl: string = "http://localhost:61000/Jiya/";
  serviceBaseUrl: string = "";

  constructor(private http: Http) {
    this.serviceBaseUrl = environment.serviceBaseUrl;
  }

  ImageUpload(fileList: FileList) {
    let file: File = fileList[0];
    let formData: FormData = new FormData();
    formData.append('uploadImageFile', file, file.name);
    let headers = new Headers()
    //headers.append('Content-Type', 'json');  
    //headers.append('Accept', 'application/json');  
    let options = new RequestOptions({ headers: headers });
    let apiUrl1 = this.serviceBaseUrl + "ImageUpload";
    return this.http.post(apiUrl1, formData, options).toPromise();

    // this.http.post(apiUrl1, formData, options)
    //   .map(res => { let r = res.json(); console.log(r); })
    //   .catch(error => Observable.throw(error))
    //   .subscribe(
    //     data => console.log('success', data),
    //     error => console.log(error)
    //   )
    //window.location.reload();
  }
}
