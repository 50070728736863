import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { Router } from '@angular/router';

declare var $: any;
const SESSION_KEY = 'Email';
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  isUpdateMode: boolean = false;
  userArray: any = {};
  userDetailArray: any;
  sessionToken = "";
  constructor(private userService: UserService, private router: Router) { }

  public static isValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ngOnInit() {
    this.sessionToken = localStorage.getItem(SESSION_KEY);
    if (this.sessionToken != null) {
      this.userService.getUserDetail().then(res => {
        this.userDetailArray = res;
      });
    }
    else {
      this.router.navigate(['admin/login']);
    }
  }
  OpenInsertUserModal() {
    this.isUpdateMode = false;
    this.userArray = {};
    $("#userModal").modal('show');
  }

  OpenUpdateUserModal(editData: any) {
    this.isUpdateMode = true;
    this.userArray = editData;
    $("#userModal").modal('show');
  }

  IsDelete(Id) {
    if (confirm("Are you sure want to delete?")) {
      this.userService.DeleteUser(Id).then(() => {
        alert("Selected User Deleted Successfully.");
        location.reload();
      })

    }
  }
  insertUserDetail() {
    let isValid = AdminUsersComponent.isValid(this.userArray.Email);
    if (this.userArray.Email && isValid) {
      if (this.userArray.Password == this.userArray.ConfirmPassword) {
        this.userService.insertUserDetailData(this.userArray).then(res => {
          alert("User Detail Added Successfully");
          location.reload();

        }).catch(e => {
          console.log(e);
        })
      }
      else {
        alert("Password does not match the confirm password.");
      }
    }
    else {
      alert("Please Enter Required(*) Field or Valid Email Address.");
    }
  }

  updateUserDetail() {
    let isValid = AdminUsersComponent.isValid(this.userArray.Email);
    if (this.userArray.Email && isValid) {
      this.userService.updateUserDetailData(this.userArray).then(res => {
        alert("User Detail Updated Successfully");
        location.reload();
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      alert("Please Enter Required(*) Field or Valid Email Address.");
    }
  }
}
