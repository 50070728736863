import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-about-us',
  templateUrl: './admin-about-us.component.html',
  styleUrls: ['./admin-about-us.component.css']
})
export class AdminAboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
