import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-client-service',
  templateUrl: './client-service.component.html',
  styleUrls: ['./client-service.component.css']
})
export class ClientServiceComponent implements OnInit {
  imgBaseUrl: string = "";

  constructor(private router: Router) {
    this.imgBaseUrl = environment.imgBaseUrl;
  }

  ngOnInit() {
  }

}
