import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToursService {

   // serviceBaseUrl: string = "http://localhost:61000/Jiya/";
   serviceBaseUrl: string = "";

   constructor(private httpClient: HttpClient) {
     this.serviceBaseUrl = environment.serviceBaseUrl;
   }

  getTourDetail() {
    return this.httpClient.get(this.serviceBaseUrl + "GetTours").toPromise();
  }
  insertTourDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "InsertTours", obj).toPromise();
  }
  updateTourDetailData(obj: any) {
    return this.httpClient.post(this.serviceBaseUrl + "UpdateTours", obj).toPromise();
  }
  DeleteTour(Id: string) {
    return this.httpClient.get(this.serviceBaseUrl + "DeleteTours?Id=" + Id).toPromise();
  }
  getDestinationDetail() {
    return this.httpClient.get(this.serviceBaseUrl + "GetDestinations").toPromise();
  }
}
